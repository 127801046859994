<template>
  <div class="content">
    <h2>{{ content.name }}</h2>
    <div>
      <textarea 
        v-model="htmlContent" 
        class="html-editor"
        spellcheck="false"
        @keydown.tab.prevent="handleTab"
      ></textarea>
    </div>
    <div class="btn-group content__admin">
      <button class="btn btn-red" @click="saveContent">
        Aktualizovat stránku
      </button>
      <button class="btn" @click="$router.go(-1)">
        Zpět
      </button>
    </div>
    <Loader 
      :show="loading" 
      :type="loading ? 'loading' : (saveSuccess ? 'success' : 'error')" 
      class="loader--inline"
    ></Loader>
  </div>
</template>

<script>
import axios from 'axios'

// Varianta 1: Funkce mimo komponentu
const formatHtml = (html) => {
  html = html.trim().replace(/>\s+</g, '><')
  
  let formatted = ''
  let indent = 0
  const tab = '  '
  
  const noBreakTags = new Set(['br', 'img', 'input', 'hr'])
  const keepWithNext = new Set(['li', 'dt', 'dd', 'td', 'th', 'p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'])
  
  const tokens = html.split(/(<\/?[^>]+>)/)
  
  for (let i = 0; i < tokens.length; i++) {
    const token = tokens[i]
    
    if (!token) continue
    
    if (token.startsWith('</')) {
      const tagName = token.match(/<\/([a-zA-Z0-9]+)/)?.[1]?.toLowerCase()
      if (!noBreakTags.has(tagName)) {
        indent--
      }
      if (!keepWithNext.has(tagName)) {
        formatted += '\n' + tab.repeat(Math.max(0, indent))
      }
      formatted += token
    } else if (token.startsWith('<')) {
      const tagName = token.match(/<([a-zA-Z0-9]+)/)?.[1]?.toLowerCase()
      if (i > 0 && !keepWithNext.has(tokens[i-1]?.match(/<\/([a-zA-Z0-9]+)/)?.[1]?.toLowerCase())) {
        formatted += '\n' + tab.repeat(indent)
      }
      formatted += token
      if (!noBreakTags.has(tagName) && !token.endsWith('/>')) {
        indent++
      }
    } else {
      const text = token.trim()
      if (text) {
        if (i > 0 && tokens[i-1]?.startsWith('<') && !tokens[i-1]?.startsWith('</')) {
          formatted += text
        } else {
          formatted += '\n' + tab.repeat(indent) + text
        }
      }
    }
  }
  
  return formatted.trim()
}

export default {
  name: 'EditHtml',
  data() {
    return {
      content: {
        id: null,
        slug: null,
        name: null,
        content: null,
      },
      htmlContent: '',
      loading: false,
      saveSuccess: null
    }
  },
  methods: {
    handleTab(e) {
      const start = e.target.selectionStart
      const end = e.target.selectionEnd
      this.htmlContent = this.htmlContent.substring(0, start) + "\t" + this.htmlContent.substring(end)
      this.$nextTick(() => {
        e.target.selectionStart = e.target.selectionEnd = start + 1
      })
    },
    
    async saveContent() {
      this.loading = true
      try {
        await axios.post('/content.php', {
          save: true,
          update: this.$route.params.slug,
          slug: this.$route.params.slug,
          name: this.content.name,
          img: this.content.img,
          content: this.htmlContent
        })
        this.saveSuccess = true
        window.location.href = `/${this.$route.params.slug}`
      } catch (e) {
        this.saveSuccess = false
        if (e.response.status == 403) {
          this.$root.$children[0].showLogin = true
        }
      }
      this.loading = false
    }
  },
  created() {
    axios.get('/content.php?slug=' + this.$route.params.slug)
      .then(res => {
        if (res.data.length) {
          this.content = res.data[0]
          // Použijeme formatHtml při načtení obsahu
          this.htmlContent = formatHtml(this.content.content)
        } else {
          this.$router.replace({ name: 'Home' })
        }
      })
      .catch(() => {
        this.$router.replace({ name: 'Home' })
      })
  }
}
</script>

<style>
.html-editor {
  width: 100%;
  min-height: 70vh;
  font-family: monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 16px;
  border: 1px solid var(--mid-gray);
  border-radius: 8px;
  background: #fff;
  color: var(--dark-gray);
  white-space: pre;
  tab-size: 2;
  resize: vertical;
  margin: 16px 0;
}

.html-editor:focus {
  border-color: var(--dark-gray);
  outline: none;
}
</style>